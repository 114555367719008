import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import App from "./app";
import { blue, green } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LicenseInfo } from "@mui/x-license-pro";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Theme() {
	LicenseInfo.setLicenseKey("962235017b1fc641c4cdab13fa9ad42eTz04MDk4NCxFPTE3MzQ4MTk0NjIwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y");
	const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
	const theme = createTheme({
		palette: {
			mode: prefersDarkMode ? "dark" : "light",
			primary: {
				main: blue[700],
				light: blue[600],
				dark: blue[800]
			},
			secondary: {
				main: green[700],
				light: green[600],
				dark: green[800]
			}
		}
	});
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline enableColorScheme />
			<App />
		</ThemeProvider>
	);
}