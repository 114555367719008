import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Error from "./routes/error";
import Home from "./routes/home";
import Root from "./routes/root";

export default function Router() {
	const router = createBrowserRouter([
		{
			path: "/",
			element: <Root />,
			errorElement: <Error />,
			children: [
				{
					index: true,
					element: <Home />
				}
			]
		}
	]);
	return (
		<RouterProvider router={router} />
	);
}

export function setDocumentTitle(subtitle) {
	document.title = subtitle ? "Lab Axiom | " + subtitle : "Lab Axiom";
}