import { setDocumentTitle } from "../router";

const APP_URL = process.env.REACT_APP_URL;

export default function Home() {
	setDocumentTitle();
	return (
		<div style={{ background: "#6699CC", display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
			<a href={APP_URL}>
				<img
					src="/images/lab-axiom-logo-tall.svg"
					alt="Lab Axiom"
					width="300px"
					style={{ filter: "brightness(0) saturate(100%) invert(1)" }}
				>
				</img>
			</a>
		</div>
	);
}